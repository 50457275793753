import "./News.css"
import img5 from '../../images/img5.jpeg'
import { DlabSeparator, SectionHead } from "../../component"

const News = () => {
    return (
        <>
            <section className="content-inner-goals">
                <div className="container">
                    <h2 className='h2-goals'>رؤيتنا</h2>
                    <SectionHead classname=" style-3 text-center">
                        <h5 className="title">
                            دعم القطاع العسكري والأمني بالإمكانات المتاحة، ودفع عجلة التنمية بشكل مباشر <br />والمساهمة
                            في زيادة الناتج المحلي الإجمالي بحلول العام 2030م، وذلك لتعزز قدرات <br />القوات المسلحة
                            واألجهزة األمنية في المملكة من استقالليتها وجاهزيتها ، من خلال<br /> المساعدة في بناء قطاع
                            صناعات عسكرية وأمنية محلية

                        </h5>
                        <DlabSeparator classname=" style-2 bg-primary"></DlabSeparator>
                    </SectionHead>



                </div>
                <div className="contai">
                    <div className="row ">
                        <div className="col-lg-12" data-wow-duration="2s" data-wow-delay="0.4s">
                            <div >
                                <img src={img5} className="move-2  center-I" alt="" />
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default News