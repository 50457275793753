import { Helmet } from "react-helmet-async"
import { Goals } from "../../Sections"
import "./OurGoals.css"
import { useTranslation } from "react-i18next";

const OurGoals = () => {
    const [t, i18n] = useTranslation();
    return (
        <>
            <Helmet>
                <title>{t("s-3")}</title>
                <meta name="description" content={t("header-title")} data-rh="true" />
                <link rel="canonical" href="/ourgoals" />
            </Helmet>
            <Goals />
        </>
    )
}

export default OurGoals