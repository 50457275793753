import "./SectionHead.css"
const SectionHead = (props) => {
    return (
        <div className={"section-head" + props.classname}>
            {props.children}
        </div>

    )
}

export default SectionHead