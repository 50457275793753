import React from 'react'
import { AboutUs, BlogSection, Client, GetInTouch, HeaderHero, Service, Ssection } from '../../Sections'
import { useTranslation } from 'react-i18next';
const Home = () => {
    const [t, i18n] = useTranslation();
    return (
        <>
            <HeaderHero />
            <Service />
            <Client />
            <AboutUs />
            <BlogSection />
            <GetInTouch />
            <Ssection />
        </>
    )
}

export default Home
