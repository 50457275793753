import { Helmet } from "react-helmet-async"
import { ContactUsGet } from "../../Sections"
import "./ContactUs.css"
import { useTranslation } from "react-i18next";


const ContactUs = () => {
    const [t, i18n] = useTranslation();
    return (
        <>
            <Helmet>
                <title>{t("title5")}</title>
                <meta name="description" content={t("header-title")} data-rh="true" />
                <link rel="canonical" href="/contact" />
            </Helmet>
            <div className="container-fluid m-0 p-0 ">
                <ContactUsGet />
            </div>
        </>
    )
}

export default ContactUs