import { ContentInner, SectionHead, Card } from "../../component"
import "./BlogSection.css"
import { useTranslation } from 'react-i18next'


const BlogSection = (props) => {
    const [t, i18n] = useTranslation();

    return (
        <>
            {/* <!-- Service --> */}
            <ContentInner>
                <div className="container"  >
                    <SectionHead classname=" style-3 text-center mb-4 mar">
                        <h4 className="title">{t("sou-title5")}</h4>
                        <div className="dlab-separator style-2 bg-primary"></div>
                    </SectionHead>
                    <div className="row">
                        <div className="col-lg-3 col-md-12">
                            <Card classname=" first-btn-blog" title={t("member1")} name={t("name1")} description={t("member-content1")} />
                        </div>
                        <div className="col-lg-3 col-md-12">
                            <Card classname=" second-btn-blog" title={t("member2")} name={t("name2")} description={t("member-content2")} />
                        </div>
                        <div className="col-lg-3 col-md-12">
                            <Card classname=" third-btn-blog" title={t("member3")} name={t("name3")} description={t("member-content3")} />
                        </div>
                        <div className="col-lg-3 col-md-12">
                            <Card classname=" fourth-btn-blog" title={t("member3")} name={t("name4")} description={t("member-content4")} />
                        </div>
                    </div>
                </div>
            </ContentInner>
        </>
    )
}

export default BlogSection
