import React, { useState } from 'react'
import "./Service.css"
import { ContentInner, DlabSeparator, IconBxWraper, PrimaryBtn, SectionHead } from '../../component'
import { useTranslation } from 'react-i18next'



const Service = () => {
    const [open, setOpen] = useState("p2")
    const [t, i18n] = useTranslation();
    return (
        <>
            {/* <!-- Service --> */}
            <ContentInner>
                <div className="container">
                    <SectionHead classname=" style-3 text-center mb-4">
                        <h4 className="title title-sm">
                            {t("service-content")}
                        </h4>
                        <DlabSeparator classname=" style-2 bg-primary"></DlabSeparator>
                    </SectionHead>

                    <div className="row">
                        <div className="col-lg-4 col-md-6 wow fadeInUp icon-box-con" data-wow-duration="2s" data-wow-delay="0.2s">
                            <IconBxWraper classname={`${open === "p1" ? "icon-bx-wraper style-5 mili-1 box-hover text-center m-b30 active" : "icon-bx-wraper style-5 mili-1 box-hover text-center m-b30"}`}>
                                <div className="n-content">
                                    <h4 className="center-t "> {t("sou-title3")} </h4>
                                </div>
                            </IconBxWraper>
                            <PrimaryBtn re="/distinguishes" />
                        </div>

                        {/* ///////////////////////////////////////////////////////////////////////////////////// */}

                        <div className="col-lg-4 col-md-6 wow fadeInUp icon-box-con" data-wow-duration="2s" data-wow-delay="0.2s">
                            <IconBxWraper classname={`${open === "p1" ? "icon-bx-wraper style-5 mili-2 box-hover text-center m-b30 active" : "icon-bx-wraper style-5 mili-2 box-hover text-center m-b30"}`}>
                                <div className="n-content">
                                    <h4 className="center-t ">{t("sou-title2")}</h4>
                                </div>
                            </IconBxWraper>
                            <PrimaryBtn re="/vision" />
                        </div>

                        {/* ///////////////////////////////////////////////////////////////////////////////////// */}


                        <div className="col-lg-4 col-md-6 wow fadeInUp icon-box-con" data-wow-duration="2s" data-wow-delay="0.2s">
                            <IconBxWraper classname={`${open === "p1" ? "icon-bx-wraper style-5 mili-3 box-hover text-center m-b30 active" : "icon-bx-wraper style-5 mili-3 box-hover text-center m-b30"}`}>
                                <div className="n-content">
                                    <h4 className="center-t ">{t("sou-title1")}</h4>
                                </div>
                            </IconBxWraper>
                            <PrimaryBtn re="/ourgoals" />
                        </div>
                    </div>
                </div>
            </ContentInner>
        </>
    )
}

export default Service
