import "./DlabSeparator.css"
// "dlab-separator style-2 bg-primary"

const DlabSeparator = (props) => {
    return (
        <div className={"dlab-separator" + props.classname}>
            {props.children}
        </div>
    )
}

export default DlabSeparator