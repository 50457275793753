import { DlabSeparator, SectionHead } from "../../component"
import "./OurVision.css"
import img5 from '../../images/img5.jpeg'
import { useTranslation } from "react-i18next";

const OurVision = () => {
    const [t, i18n] = useTranslation();
    return (
        <>
            <section className="content-inner-goals">
                <div className="container">
                    <h2 className='h2-goals'>{t("sou-title2")}</h2>
                    <SectionHead classname=" style-3 text-center">
                        <h5 className="title">
                            {t("vision")}
                        </h5>
                        <DlabSeparator classname=" style-2 bg-primary"></DlabSeparator>
                    </SectionHead>



                </div>
                <div className="contai">
                    <div className="row ">
                        <div className="col-lg-12" data-wow-duration="2s" data-wow-delay="0.4s">
                            <div >
                                <img src={img5} className="move-2  center-I" alt="" />
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default OurVision