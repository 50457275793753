import React, { useState } from 'react'
import img56 from '../../images/img56.png'
import { DlabSeparator, SectionHead } from "../../component"
import "./Goals.css"
import { useTranslation } from 'react-i18next'
const Goals = () => {
    const [open, setOpen] = useState("p2")
    const [t, i18n] = useTranslation();
    return (
        <>
            <section className="content-inner-goals">
                <div className="container">
                    <h2 className='h2-goals'>{t("Goals")}</h2>
                    <SectionHead classname=" style-3 text-center">
                        <h5 className="title">
                            {t("goals1")} <br />
                            <ul className='ul-goals'>
                                <li>{t("goals2")}</li>
                                <li>{t("goals3")}</li>
                                <li>{t("goals3")}</li>
                            </ul>
                        </h5>
                        <DlabSeparator classname=" style-2 bg-primary"></DlabSeparator>
                    </SectionHead>



                </div>
                <div className="contai">
                    <div className="row ">
                        <div className="col-lg-12" data-wow-duration="2s" data-wow-delay="0.4s">
                            <div className='img-con-goals' >
                                <img src={img56} className="move-2  center-I" alt="" />
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default Goals
