import "./Card.css"
import { Button, Modal } from "react-bootstrap";
import { useState } from "react";
import { useTranslation } from 'react-i18next'

const Card = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [t, i18n] = useTranslation();

    return (
        <>
            <div className="card-con">
                <div className="circle"></div>
                <h5>{props.title}<br />
                    {props.name}
                </h5>
                <div className="btn-con-blog">
                    <Button variant="primary" onClick={handleShow} className={"btn-modal" + props.classname}>
                        {t("cv")}
                    </Button>
                    <Modal show={show} onHide={handleClose} centered aria-labelledby="contained-modal-title-vcenter">
                        <Modal.Header >
                            <Modal.Title>{t("cv")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {props.description}
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        </>
    )
}

export default Card