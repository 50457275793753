import { Helmet } from "react-helmet-async"
import { OurVision } from "../../Sections"
import "./Vision.css"
import { useTranslation } from "react-i18next";

const Vision = () => {
    const [t, i18n] = useTranslation();
    return (
        <>
            <Helmet>
                <title>{t("s-2")}</title>
                <meta name="description" content={t("header-title")} data-rh="true" />
                <link rel="canonical" href="/vision" />
            </Helmet>
            <OurVision />
        </>
    )
}

export default Vision