import { useTranslation } from "react-i18next";
import { ContentInner, SectionHead, Card } from "../../component"
import { Helmet } from "react-helmet-async";

const ExecutiveManagement = () => {
    const [t, i18n] = useTranslation();
    return (
        <>
            <Helmet>
                <title>{t("sou-title4")}</title>
                <meta name="description" content={t("header-title")} data-rh="true" />
                <link rel="canonical" href="/ExecutiveManagement" />
            </Helmet>
            <ContentInner>
                <div className="container bot" >
                    <SectionHead classname=" style-3 text-center mb-4 mar">
                        <h4 className="title">{t("sou-title4")}</h4>
                        <div className="dlab-separator style-2 bg-primary"></div>
                    </SectionHead>
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <Card classname=" second-btn-blog" title={t("excutiveManger")} name={t("name2")} description={t("member-content2")} />
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <Card classname=" fourth-btn-blog" title={t("excutiveManger1")} name={t("name4")} description={t("member-content4")} />
                        </div>
                    </div>
                </div>
            </ContentInner>
        </>
    )
}

export default ExecutiveManagement