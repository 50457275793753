import { Helmet } from "react-helmet-async"
import { Board0fDirectors } from "../../Sections"
import "./BoardDirectors.css"
import { useTranslation } from "react-i18next";

const BoardDirectors = () => {
    const [t, i18n] = useTranslation();
    return (
        <>
            <Helmet>
                <title>{t("sou-title5")}</title>
                <meta name="description" content={t("header-title")} data-rh="true" />
                <link rel="canonical" href="/BoardDirectors" />
            </Helmet>
            <Board0fDirectors />
        </>
    )
}

export default BoardDirectors