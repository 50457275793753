
import { useEffect, useState } from "react";
import { Home, OurGoals, ContactUs, Vision, Distinguishes, BoardDirectors, ExecutiveManagement, News } from "./Pages";
import './App.css';
import { Helmet } from 'react-helmet'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Button from './component/elements/Button';
import * as location from './images/earth.json'
import Lottie from 'react-lottie'
import Header from "./component/layouts/Header";
import { Footer } from "./Sections";
import { useTranslation } from "react-i18next";
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: location.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

function App() {
  const [t, i18n] = useTranslation();
  const [data, setData] = useState([]);
  const [Loding, setLoading] = useState(undefined);
  const [Completed, setCompleted] = useState(undefined);

  useEffect(() => {



    setTimeout(() => {
      setCompleted(true);
    }, 0);

  }, []);
  return (
    <>

      {!Completed ? (

        <header>
          <h1 className="banner-content text-white">
            <h1 className="m-b20 ttl">
              {t("header-title")}
            </h1>
          </h1>
          <Lottie options={defaultOptions}
            height={400}
            width={400}
          />
        </header>




      ) : (
        <>
          <BrowserRouter>
            <Helmet>
              <title> {t("header-title")}</title>
              <meta name="description" content={t("service-content")} />
            </Helmet>
            <div className="App">
              <Header />
              <Switch>
                <Route path="/" component={Home} exact />
                <Route path="/vision" component={Vision} />
                <Route path="/BoardDirectors" component={BoardDirectors} />
                <Route path="/ExecutiveManagement" component={ExecutiveManagement} />
                <Route path="/contact" component={ContactUs} />
                <Route path="/distinguishes" component={Distinguishes} />
                <Route path="/ourgoals" component={OurGoals} />
                <Route path="/News" component={News} />
              </Switch>
              <Button />
              <Footer />
            </div>
          </BrowserRouter>

        </>
      )}
    </>
  );
}

export default App;
