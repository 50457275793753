




function Button() {
    return (
        <>
<a href="#top">
<button
  className="scroltop icon-up"
  type="button"
  style={{ display: "inline-block" }}
>
  <i className="fa fa-arrow-up" />
</button>
</a>
</>
    )
}

export default Button