
import { Helmet } from "react-helmet-async";
import News from "../../Sections/News/News"
import "./News.css"
import { useTranslation } from "react-i18next";



const LastNews = () => {
    const [t, i18n] = useTranslation();
    return (
        <>
            <Helmet>
                <title>{t("AboutTitle")}</title>
                <meta name="description" content={t("header-title")} data-rh="true" />
                <link rel="canonical" href="/LastNews" />
            </Helmet>
            <News />
        </>
    )
}

export default LastNews