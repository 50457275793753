import React, { useState } from 'react'
import "./ContentInner"


const ContentInner = (props) => {
    const [bac, setBac] = useState({ props })
    return (
        <>
            <section className={"content-inner"}>
                {props.children}
            </section >
        </>

    )
}

export default ContentInner