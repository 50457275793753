import { useState } from "react"
import "./IconBxWraper.css"


const IconBxWraper = (props) => {
    const [open, setOpen] = useState("p2")
    return (
        <div className={props.classname} onMouseOver={() => setOpen("p1")}>
            {props.children}
        </div>
    )
}

export default IconBxWraper