import { DlabSeparator, SectionHead } from "../../component"
import "./Distinguishes.css"
import img5 from '../../images/mi.jpg'
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const Distinguishes = () => {
    const [t, i18n] = useTranslation();
    return (
        <>
            <Helmet>
                <title>{t("sou-title3")}</title>
                <meta name="description" content={t("header-title")} data-rh="true" />
                <link rel="canonical" href="/distinguishes" />
            </Helmet>
            <section className="content-inner-goals">
                <div className="container">
                    <h2 className='h2-goals'> {t("s-1")} </h2>
                    <SectionHead classname=" style-3 text-center">
                        <h5 className="title">
                            {t("s-1-title")}

                        </h5>
                        <DlabSeparator classname=" style-2 bg-primary"></DlabSeparator>
                    </SectionHead>



                </div>
                <div className="contai">
                    <div className="row ">
                        <div className="col-lg-12" data-wow-duration="2s" data-wow-delay="0.4s">
                            <div >
                                <img src={img5} className="move-2  center-I" alt="" />
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default Distinguishes