import "./Client.css"

const Client = () => {
    return (
        <>

            {/* <!-- Clients Logo --> */}
            <div className="p-tb50 bg-gray">
                <div className="container">
                    <div className="clients-carousel owl-none owl-carousel">

                    </div>
                </div>
            </div>
        </>
    )
}

export default Client