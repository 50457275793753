import React from 'react'
import "./SecondaryBtn.css"
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const SecondaryBtn = () => {
    const [t, i18n] = useTranslation();
    return (<>
        <Link to="/News" className="btn btn-outline-secondary"><p className="center-t "> {t("btn-m")}</p></Link>
    </>
    )
}

export default SecondaryBtn