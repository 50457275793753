import "./Board0fDirectors.css"
import { BlogSection } from "../../Sections"

const Board0fDirectors = () => {
    return (
        <>
            <BlogSection classname=" bot" />
        </>
    )
}

export default Board0fDirectors