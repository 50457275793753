import { Link } from "react-router-dom"
import "./PrimaryBtn.css"
import { useTranslation } from "react-i18next";

const PrimaryBtn = (props) => {
    const [t, i18n] = useTranslation();
    return (
        <Link to={props.re} className="btn btn-outline-primary"><p className="center-t "> {t("btn-m")}</p></Link>
    )
}

export default PrimaryBtn