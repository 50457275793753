import "./AboutUs.css"
import img5 from '../../images/img5.jpeg'
import { ContentInner, DlabSeparator, SecondaryBtn, SectionHead } from "../../component"
import { useTranslation } from "react-i18next";
const AboutUs = () => {
    const [t, i18n] = useTranslation();
    return (
        <>
            {/* <!-- About Us --> */}
            <ContentInner >
                <div className="container">
                    <div className="row about-us-con">
                        <div className="col-lg-6 m-b30">
                            <div className="img-abou-con">
                                <img src={img5} className="move-2 militaryBottom" alt="" />
                            </div>
                        </div>

                        <div className="col-lg-6 cl-about-text-con ">
                            <SectionHead classname=" style-3 section-head-about">
                                <h2 className="title">
                                    {t("AboutTitle")}
                                </h2>
                                <DlabSeparator classname=" style-2 bg-primary"></DlabSeparator>
                            </SectionHead>
                            <br />
                            <div className="pad-h4-about">
                                <h4 className="h4-about">
                                    {t("news")}
                                </h4>
                            </div>
                            <br />
                            <SecondaryBtn />
                        </div>
                    </div>
                </div>
            </ContentInner>

        </>
    )
}

export default AboutUs
