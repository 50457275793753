import "./HeaderHero.css"
import * as loti from '../../images/start3.json'
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import { useState } from "react";
// data-wow-delay="1s" data-wow-duration="3s wow fadeInUp " 

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loti.default,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const HeaderHero = () => {
    const [t, i18n] = useTranslation();
    return (
        <>
            <Helmet>
                <title>'{t("header-title")}'</title>
                <meta name="description" content={t("header-title")} data-rh="true" />
                <link rel="canonical" href="/" />
            </Helmet>

            <div className="banner-three bg-primary bghero"  >
                <div className="container" >
                    <div className="banner-inner">
                        <div className="row align-items-center">
                            <div className="col-md-12">
                                <h1 className="banner-content text-white">
                                    <h1 className="m-b20 ttl">
                                        {t("header-title")}
                                    </h1>
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default HeaderHero
