import "./Ssection.css"
import { DlabSeparator, SectionHead } from '../../component'

const Ssection = () => {

    return (
        <>

            <div className='bac-i content-inner'>

            </div>
        </>
    )
}

export default Ssection
